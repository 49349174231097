<template>
  <div>
    <el-form
      :model="form"
      status-icon
      ref="form"
      label-width="100px"
      class="qt-form"
      :rules="formRules"
    >
      <template v-for="f in eidtableFormItems">
        <el-form-item
          v-if="isShowFormItem(f)"
          :key="f.prop"
          :label="f.label"
          :prop="f.prop"
        >
          <el-upload
            v-if="f.type === 'upload'"
            ref="uploadRef"
            action="/api/cloud/qtVersion/save"
            :data="uploadExtraParams"
            :headers="uploadHeaders"
            accept=".exe"
            class="qt-upload"
            :multiple="false"
            :file-list="form[f.prop]"
            :auto-upload="false"
            :on-change="fileChange"
            :on-remove="fileRemove"
            :before-upload="beforeUpload"
            :on-success="successCall"
            :on-error="errorCall"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
            <div slot="tip" class="el-upload__tip">只能上传.exe文件</div>
          </el-upload>
          <el-radio-group v-else-if="f.type === 'radio'" v-model="form[f.prop]">
            <el-radio
              v-for="item in formOptions[f.prop]"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
          <el-input
            v-else
            v-model="form[f.prop]"
            :type="f.inputType || 'text'"
            :disabled="f.prop === 'name' && isEdit"
            :placeholder="f.placeholder"
          ></el-input>
        </el-form-item>
      </template>

      <el-form-item>
        <el-button type="primary" @click="submitForm">确认保存</el-button>
        <el-button @click="$router.push('/qt')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      form: this.initForm(),
      eidtableFormItems: [
        {
          prop: "file",
          label: "上传qt",
          type: "upload",
        },
        {
          prop: "name",
          label: "版本号",
          placeholder: "请输入",
        },
        {
          prop: "white_list_status",
          label: "白名单状态",
          placeholder: "请输入",
          type: "radio",
        },
        {
          prop: "white_list",
          label: "白名单设置",
          inputType: "textarea",
          placeholder: "设备唯一标识 以回车分隔",
        },
        {
          prop: "version_log",
          label: "更新日志",
          placeholder: "请输入",
          inputType: "textarea",
        },
        {
          prop: "status",
          label: "启用",
          type: "radio",
        },
        {
          prop: "remark",
          label: "版本说明",
          placeholder: "请输入",
          inputType: "textarea",
        },
      ],
      formOptions: {
        white_list_status: [
          { label: "是", value: "true" },
          { label: "否", value: "false" },
        ],
        status: [
          { label: "是", value: "true" },
          { label: "否", value: "false" },
        ],
      },
      formRules: {
        file: [{ required: true, message: `请选择文件`, trigger: "change" }],
        name: [{ required: true, message: `请输入版本号`, trigger: "blur" }],
        white_list_status: [
          { required: true, message: "请选择白名单状态", trigger: "blur" },
        ],
        white_list: [
          { required: true, message: "请输入白名单设置", trigger: "blur" },
        ],
        version_log: [
          { required: true, message: `请输入更新日志`, trigger: "blur" },
        ],
      },
      isEdit: false
    };
  },
  computed: {
    ...mapState([
      'loginAccountInfo'
    ]),
    uploadExtraParams() {
      let params = {
        ...this.form
      };
      delete params.file;
      return {
        qt_version_pro: JSON.stringify(params)
      };
    },
    uploadHeaders() {
      return {
        token: this.loginAccountInfo.token
      }
    },
    isShowFormItem() {
      return (f) => {
        if (f.prop === 'white_list') {
          if (this.form.white_list_status !== 'true') {
            return false;
          }
        }
        if (f.type === 'upload') {
          if (this.isEdit) {
            return false;
          }
        }
        return true;
      }
    }
  },
  mounted() {
    this.getInitInfo();
  },
  methods: {
    ...mapActions(["findQtDataById", "saveQt", "updateQtData"]),
    fileChange(file, fileList) {
      if (fileList.length > 0) {
        this.form.file = [fileList[fileList.length - 1]];
      }
    },
    fileRemove() {
      this.form.file = [];
    },
    initForm() {
      return {
        id: null,
        file: [],
        name: "",
        white_list_status: "false",
        white_list: "",
        version_log: "",
        status: "true",
        remark: "",
      };
    },
    async getInitInfo() {
      const { id } = this.$route.query;
      if (!id) {
        this.isEdit = false;
        return;
      }
      this.isEdit = true;
      this.$changeLoadingState(true);
      const ret = await this.findQtDataById({ id });
      this.$changeLoadingState(false);
      if (ret && ret.status === "success") {
        const result = ret.data || {};
        Object.keys(this.form).forEach((key) => {
          if (result.hasOwnProperty(key)) {
            this.form[key] = result[key];
          }
        });
      } else {
        this.$message.error(ret && ret.msg);
      }
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            // 修改接口
            this.$changeLoadingState(true);
            let params = {
              ...this.form
            };
            delete params.file;
            const ret = await this.updateQtData(params);
            this.$changeLoadingState(false);
            if (ret && ret.status === "success") {
              this.$router.push("/qt");
            } else {
              this.$message.error(ret && ret.msg);
            }
          } else {
            this.$refs.uploadRef[0].submit();
          }
        }
      });
    },
    beforeUpload() {
      this.$changeLoadingState(true);
    },
    successCall(ret) {
      this.$changeLoadingState(false);
      if (ret && ret.status === "success") {
        this.$router.push("/qt");
      } else {
        this.$message.error(ret && ret.msg);
      }
    },
    errorCall(ret) {
      this.$changeLoadingState(false);
      this.$message.error(ret && ret.msg);
    }
  },
};
</script>

<style lang='scss'>
.qt-form {
  background-color: #fff;
  padding: 24px 30% 24px 24px;
  .el-cascader--small {
    width: 100%;
  }
  .el-form-item__label {
    width: 30% !important;
  }
  .el-form-item__content {
    margin-left: 30% !important;
    .el-select {
      display: block;
    }
  }
}

.qt-upload {
  .el-list-enter-active,
  .el-list-leave-active {
    transition: none;
  }

  .el-list-enter,
  .el-list-leave-active {
    opacity: 0;
  }
  .el-upload-list {
    max-height: 40px;
  }
}
</style>
<style lang="scss" scoped>
.el-upload__tip {
  display: inline-block;
  margin-left: 10px;
  margin-top: 0px;
}
</style>